<!-- AI绘画 作品集 -->

<template>
	<div>
		<div style="height: 40px;"></div>
		<a-card :bordered="false" style="width: 100%;margin-left: 0px;" class="card-profile-head" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" style="display: flex; align-items: center; ">
						<a-radio-group v-model="tabsId" size="small">
							<a-radio-button :value="item.id" v-for="(item, index) in tabsList" :key="'tab_' + item.id">{{ item.title }}</a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</template>
		</a-card>
		<div v-if="tabsId === 1">
			<div class="atlas_box" v-if="videoList.length">
				<div class="list_box">
					<div class="list">
						<waterfall :col="5" :gutterWidth="20" :data="videoList">
							<template>
                  <a-card v-for="(item, index) in videoList" hoverable >
                      <vue-plyr  :options="plyrOptions">
                          <video controls crossorigin playsinline data-poster="poster.jpg">
                              <source size="1080" :src="item.video_url" type="video/mp4" />
                          </video>
                      </vue-plyr>
                      <a-card-meta class="card_info" title="作品信息">
                          <template slot="description">
                              <div>提示词:{{item.prompt}}</div>
                              <div>创作时间:{{item.created_at}}</div>
                              <div>比例:{{item.ratio}}</div>
                          </template>
                      </a-card-meta>
                  </a-card>

<!--                  <div v-for="(item, index) in videoList">-->
<!--                      <vue-plyr  :options="plyrOptions">-->
<!--                          <video controls crossorigin playsinline data-poster="poster.jpg">-->
<!--                              <source size="1080" :src="item.video_url" type="video/mp4" />-->
<!--                          </video>-->
<!--                      </vue-plyr>-->
<!--                  </div>-->
							</template>
						</waterfall>
					</div>
				</div>
			</div>
			<div class="mt-50" v-else>
				<template>
					<a-empty description="暂无数据" />
				</template>
			</div>
		</div>

	</div>
</template>

<script>
	import { Debounce } from "@/utils/tools"
	import PreviewImage from "@/components/Custom/PreviewImage.vue"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
  import PlyrVideo from 'vue-plyr';
	export default {
		components: { PreviewImage,PlyrVideo },
		data() {
			return {
				tabsId: 1,
				tabsList: [
					{ id: 1, title: "视频记录" },
				],
				atlas: {
					data: [],
					page: 1,
					pagesize: 20
				},
				collect: {
					data: [],
					page: 1,
					pagesize: 20
				},
				likes: {
					data: [],
					page: 1,
					pagesize: 20
				},
				previewImageShow: false,
				previewImageData: {
					id: "",
					desc: "",
					image: "",
					width: "",
					height: "",
					vote: false
				},
          plyrOptions: {
              // 这里可以配置 plyr 的选项
              controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
              // 更多选项请参考 plyr 的文档
          },
          videoList:''
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"])
		},
		watch: {
			tabsId(newValue, oldValue) {
				if (newValue == 2 || newValue == 3) {
					this.collect = {
						data: [],
						page: 1,
						pagesize: 20
					}
					this.likes = {
						data: [],
						page: 1,
						pagesize: 20
					}
					this.getCollectList()
				} else if (newValue == 1) {
					this.atlas = {
						data: [],
						page: 1,
						pagesize: 20
					}
					this.getDrawingRecord()
				}
			}
		},
		created() {
			this.getDrawingRecord()
			window.addEventListener("scroll", this.handleScroll, false)
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll, false)
		},
		methods: {
			...mapActions("user", ["getUserInfo"]),

			// 滚动事件
			handleScroll: Debounce(
				function () {
					let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
					let clientHeight = document.documentElement.clientHeight
					let scrollHeight = document.documentElement.scrollHeight
					if (scrollTop + clientHeight >= scrollHeight - 50) {
						if (this.tabsId === 1) {
							this.atlas.page++
							this.getDrawingRecord()
						} else if (this.tabsId === 2) {
							this.collect.page++
							this.getCollectList()
						} else if (this.tabsId === 3) {
							this.likes.page++
							this.getCollectList()
						}
					}
				},
				20,
				false
			),

			// 获取绘画记录
			getDrawingRecord() {
				this.$http("video.record", { page: this.atlas.page, pagesize: this.atlas.pagesize }).then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
                if(this.videoList == ''){
                    this.videoList = res.data;
                }else{
                    this.videoList.push(res.data);
                }

						} else {
							this.atlas.page--
						}
					}
				})
			},

		}
	}
</script>

<style lang="scss" scoped>
	.atlas_box {
		width: 100%;
		margin-bottom: 20px;

		.list_box {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			.list {
				::v-deep .vue-waterfall-column {
					width: calc((100% - 100px) / 5);
				}

				.item {
					margin-bottom: 20px;

					.img {
						position: relative;
						border-radius: 2px;
						overflow: hidden;
						cursor: pointer;

						&:hover {
							.coverage {
								display: flex;
							}
						}

						img {
							width: 100%;
							height: auto;
							border-radius: 2px;
						}

						.coverage {
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background: rgba($color: #000000, $alpha: 0.3);
							display: none;
							flex-direction: column;
							justify-content: flex-end;
							padding: 10px 10px;
							font-size: 12px;
							color: #fff;

							.time {
								color: #a5adb7;
								margin-top: 8px;
							}

							.tools {
								position: absolute;
								top: 10px;
								right: 10px;
								display: flex;
								align-items: center;

								.icon {
									width: 30px;
									height: 30px;
									border-radius: 2px;
									background: rgba($color: #000000, $alpha: 0.4);
									margin-left: 10px;
									display: flex;
									align-items: center;
									justify-content: center;

									&:hover {
										background: rgba($color: #000000, $alpha: 0.6);
									}

									&:first-child {
										margin-left: 0;
									}
								}
							}
						}
					}

					.infos {
						margin-top: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						color: #838fa2;
						font-size: 12px;

						.left {
							display: flex;
							align-items: center;
							.avatar {
								width: 22px;
								height: 22px;
								border-radius: 50%;
								overflow: hidden;
								border: 1px solid #fff;
								margin-right: 8px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}

							.nickname {
								max-width: 100px;
							}
						}

						.right {
							display: flex;
							align-items: center;

							.icons {
								display: flex;
								align-items: center;
								margin-left: 8px;

								span {
									margin-left: 2px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
    ::v-deep .ant-card-body{
        padding: 0 !important;

    }
    ::v-deep .ant-card{
        margin-bottom: 16px;
    }
    .card_info{
        padding: 16px;
    }


</style>
